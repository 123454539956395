import { AccessControl } from 'accesscontrol';

import type { Access } from 'accesscontrol';

export interface Group {
  roles: Role[];
}

export const CANDIDATE_ROLES = ['candidate_default'] as const;
export type CandidateRole = typeof CANDIDATE_ROLES[number];

export const CLINIC_ROLES = [
  'clinic_admin',
  'clinic_hrManager',
  'clinic_poolManager',
  'clinic_controller',
  'clinic_shiftplanner',
] as const;
export type ClinicRole = typeof CLINIC_ROLES[number];

export const DOCTARI_ROLES = [
  'doctari_admin',
  'doctari_demo_account',
  'doctari_developer',
  'doctari_editor',
  'doctari_manager',
  'doctari_mitarbeiter',
] as const;
export type DoctariRole = typeof DOCTARI_ROLES[number];

export const USER_ROLES = [...CLINIC_ROLES, ...DOCTARI_ROLES] as const;
export type UserRole = ClinicRole | DoctariRole;

export const ROLES = [...CANDIDATE_ROLES, ...USER_ROLES] as const;
export type Role = typeof ROLES[number];

export const ROLES_CLINIC: Group = {
  roles: [...CLINIC_ROLES],
};

export const ROLES_DOCTARI: Group = {
  roles: [...DOCTARI_ROLES],
};

export enum Resource {
  analytics,
  audit_log,
  bookings,
  booking_requests,
  booking_status,
  booking_documents,
  candidate_availabilities,
  candidate_availabilities_view,
  candidates_blocked,
  candidates_documents,
  candidates_full,
  candidates_index,
  candidates_presence,
  candidates_profiles,
  candidates_profiles_versions,
  candidates_profiles_meta_data,
  candidates_search,
  candidates_long_term_with_doctari,
  candidates_system_comments,
  clinic_prefer_temporary_employment_contracts,
  departments,
  departments_shiftTimeConfig,
  departments_shiftTimeConfig_priceCategory,
  doctari_dashboard_todos,
  price_config,
  emails,
  external_requests,
  favorites,
  healthcare_institutions,
  invite_new_users_from_candidate_profiles,
  live_requests,
  location,
  message_feeds,
  open_approvals,
  quotes_candidate,
  quotes_clinic,
  reports,
  request_candidates,
  request_candidates_swimlane,
  requests_meta_data,
  requests_perm,
  requests_temp,
  requests_temp_direct,
  requests_temp_debug,
  salesforce_full_sync,
  sendgrid_access,
  reprocess_cv_data,
  tokens,
  users,
  users_activity,
  users_doctari_contact,
  users_doctari_selectable_qualifications,
  users_password,
  users_sales_standin,
  users_salesforceId,
  change_clinic_user_email,
  dms_full_sync,
  opportunities,

  DANGEROUS_database_setup
}

// TODO make a distinction between action as user and as administrator
export type ResourcesAccess = {
  [P in 'create' | 'read' | 'update' | 'delete']: { [key: string]: boolean; }[]
};

const PERMISSIONS_TO_SET_ROLES: { role: Role, rolesToSet: UserRole[] }[] = [
  {
    role: 'doctari_developer',
    rolesToSet: [
      'clinic_admin',
      'clinic_hrManager',
      'clinic_poolManager',
      'clinic_shiftplanner',
      'clinic_controller',
      'doctari_admin',
      'doctari_manager',
      'doctari_editor',
      'doctari_mitarbeiter',
      'doctari_demo_account',
    ],
  }, {
    role: 'clinic_admin',
    rolesToSet: [
      'clinic_hrManager',
      'clinic_poolManager',
      'clinic_shiftplanner',
      'clinic_controller',
    ],
  }, {
    role: 'doctari_admin',
    rolesToSet: [
      'clinic_admin',
      'clinic_hrManager',
      'clinic_poolManager',
      'clinic_shiftplanner',
      'clinic_controller',
      'doctari_admin',
      'doctari_manager',
      'doctari_editor',
      'doctari_mitarbeiter',
      'doctari_demo_account',
    ],
  }, {
    role: 'doctari_manager',
    rolesToSet: [
      'clinic_admin',
      'clinic_hrManager',
      'clinic_poolManager',
      'clinic_shiftplanner',
      'clinic_controller',
      'doctari_mitarbeiter',
    ],
  },
];

const ac = new AccessControl();

ac.grant('candidate_default')
  .readOwn(`${Resource.bookings}`)
  .readOwn(`${Resource.booking_requests}`)
  .readOwn(`${Resource.candidate_availabilities}`)
  .updateOwn(`${Resource.candidate_availabilities}`)
  .readOwn(`${Resource.candidates_documents}`)
  .updateOwn(`${Resource.candidates_presence}`)
  .readOwn(`${Resource.candidates_profiles}`)
  .updateOwn(`${Resource.candidates_profiles_versions}`) // In order to upload a CV
  .readOwn(`${Resource.departments}`)
  .readOwn(`${Resource.healthcare_institutions}`)
  .readOwn(`${Resource.location}`)
  .readOwn(`${Resource.message_feeds}`)
  .updateOwn(`${Resource.message_feeds}`)
  .readOwn(`${Resource.quotes_candidate}`)
  .readOwn(`${Resource.request_candidates}`)
  .readOwn(`${Resource.requests_temp}`);

ac.grant('doctari_editor')
  .readAny(`${Resource.candidates_documents}`)
  .readAny(`${Resource.candidates_full}`)
  .deleteAny(`${Resource.candidates_full}`) // as of DEM-1526
  .readAny(`${Resource.candidates_profiles}`)
  .readAny(`${Resource.candidates_profiles_versions}`)
  .updateAny(`${Resource.candidates_profiles_versions}`)
  .deleteAny(`${Resource.candidates_profiles_versions}`)
  .readAny(`${Resource.candidates_profiles_meta_data}`)
  .readAny(`${Resource.candidates_search}`)
  .readOwn(`${Resource.healthcare_institutions}`)
  .readOwn(`${Resource.location}`);

ac.grant('clinic_shiftplanner')
  .readOwn(`${Resource.bookings}`)
  .createOwn(`${Resource.bookings}`)
  .readOwn(`${Resource.booking_status}`)
  .createOwn(`${Resource.candidates_blocked}`)
  .readOwn(`${Resource.candidates_blocked}`)
  .updateOwn(`${Resource.candidates_blocked}`)
  .deleteOwn(`${Resource.candidates_blocked}`)
  .readOwn(`${Resource.candidates_documents}`)
  .readOwn(`${Resource.candidates_presence}`)
  .readOwn(`${Resource.candidates_profiles}`)
  .readOwn(`${Resource.departments}`)
  .readOwn(`${Resource.external_requests}`)
  .createOwn(`${Resource.external_requests}`)
  .readOwn(`${Resource.favorites}`)
  .readOwn(`${Resource.healthcare_institutions}`)
  .readOwn(`${Resource.location}`)
  .createOwn(`${Resource.message_feeds}`)
  .readOwn(`${Resource.message_feeds}`)
  .updateOwn(`${Resource.message_feeds}`)
  .readOwn(`${Resource.request_candidates}`)
  .createOwn(`${Resource.requests_temp}`)
  .updateOwn(`${Resource.requests_temp}`)
  .readOwn(`${Resource.requests_temp}`)
  .createOwn(`${Resource.requests_temp_direct}`)
  .updateOwn(`${Resource.requests_temp_direct}`)
  .readOwn(`${Resource.requests_temp_direct}`);

ac.grant('clinic_admin')
  .readOwn(`${Resource.analytics}`)
  .createOwn(`${Resource.bookings}`)
  .readOwn(`${Resource.bookings}`)
  .updateOwn(`${Resource.bookings}`)
  .deleteOwn(`${Resource.bookings}`)
  .createOwn(`${Resource.booking_requests}`)
  .readOwn(`${Resource.booking_requests}`)
  .updateOwn(`${Resource.booking_requests}`)
  .deleteOwn(`${Resource.booking_requests}`)
  .readOwn(`${Resource.booking_status}`)
  .readOwn(`${Resource.booking_documents}`)
  .createOwn(`${Resource.candidates_blocked}`)
  .readOwn(`${Resource.candidates_blocked}`)
  .updateOwn(`${Resource.candidates_blocked}`)
  .deleteOwn(`${Resource.candidates_blocked}`)
  .readOwn(`${Resource.candidates_documents}`)
  .readOwn(`${Resource.candidates_presence}`)
  .readOwn(`${Resource.candidates_profiles}`)
  .readOwn(`${Resource.candidates_search}`)
  .createOwn(`${Resource.departments}`)
  .readOwn(`${Resource.departments}`)
  .updateOwn(`${Resource.departments}`)
  .deleteOwn(`${Resource.departments}`)
  .createOwn(`${Resource.departments_shiftTimeConfig}`)
  .readOwn(`${Resource.departments_shiftTimeConfig}`)
  .updateOwn(`${Resource.departments_shiftTimeConfig}`)
  .deleteOwn(`${Resource.departments_shiftTimeConfig}`)
  .readOwn(`${Resource.external_requests}`)
  .createOwn(`${Resource.external_requests}`)
  .readOwn(`${Resource.favorites}`)
  .createOwn(`${Resource.favorites}`)
  .deleteOwn(`${Resource.favorites}`)
  .readOwn(`${Resource.healthcare_institutions}`)
  .updateOwn(`${Resource.healthcare_institutions}`)
  .readAny(`${Resource.invite_new_users_from_candidate_profiles}`)
  .readOwn(`${Resource.location}`)
  .createOwn(`${Resource.message_feeds}`)
  .readOwn(`${Resource.message_feeds}`)
  .updateOwn(`${Resource.message_feeds}`)
  .readAny(`${Resource.open_approvals}`)
  .readOwn(`${Resource.quotes_clinic}`)
  .readOwn(`${Resource.request_candidates}`)
  .createOwn(`${Resource.requests_perm}`)
  .readOwn(`${Resource.requests_perm}`)
  .createOwn(`${Resource.requests_temp}`)
  .readOwn(`${Resource.requests_temp}`)
  .updateOwn(`${Resource.requests_temp}`)
  .createOwn(`${Resource.requests_temp_direct}`)
  .updateOwn(`${Resource.requests_temp_direct}`)
  .readOwn(`${Resource.requests_temp_direct}`)
  .createOwn(`${Resource.users}`)
  .readOwn(`${Resource.users}`)
  .updateOwn(`${Resource.users}`)
  .deleteOwn(`${Resource.users}`)
  .readOwn(`${Resource.users_doctari_selectable_qualifications}`)
  .updateOwn(`${Resource.users_doctari_selectable_qualifications}`);

ac.grant('doctari_demo_account').extend('clinic_admin');

ac.grant('clinic_controller')
  .readOwn(`${Resource.analytics}`)
  .readOwn(`${Resource.bookings}`)
  .readOwn(`${Resource.booking_status}`)
  .readOwn(`${Resource.booking_documents}`)
  .readOwn(`${Resource.candidates_documents}`)
  .readOwn(`${Resource.candidates_presence}`)
  .readOwn(`${Resource.candidates_profiles}`)
  .readOwn(`${Resource.candidates_search}`)
  .readOwn(`${Resource.departments}`)
  .readOwn(`${Resource.favorites}`)
  .readOwn(`${Resource.healthcare_institutions}`)
  .readOwn(`${Resource.location}`)
  .readOwn(`${Resource.request_candidates}`)
  .readOwn(`${Resource.requests_perm}`)
  .readOwn(`${Resource.requests_temp}`)
  .readOwn(`${Resource.requests_temp_direct}`)
  .readOwn(`${Resource.quotes_clinic}`);

ac.grant('clinic_poolManager')
  .readOwn(`${Resource.bookings}`)
  .readOwn(`${Resource.booking_requests}`)
  .readOwn(`${Resource.booking_status}`)
  .createOwn(`${Resource.candidates_blocked}`)
  .readOwn(`${Resource.candidates_blocked}`)
  .updateOwn(`${Resource.candidates_blocked}`)
  .deleteOwn(`${Resource.candidates_blocked}`)
  .readOwn(`${Resource.candidates_documents}`)
  .readOwn(`${Resource.candidates_profiles}`)
  .readOwn(`${Resource.candidates_presence}`)
  .readOwn(`${Resource.candidates_search}`)
  .readOwn(`${Resource.departments}`)
  .createOwn(`${Resource.departments}`)
  .updateOwn(`${Resource.departments}`)
  .deleteOwn(`${Resource.departments}`)
  .createOwn(`${Resource.departments_shiftTimeConfig}`)
  .readOwn(`${Resource.departments_shiftTimeConfig}`)
  .updateOwn(`${Resource.departments_shiftTimeConfig}`)
  .deleteOwn(`${Resource.departments_shiftTimeConfig}`)
  .readOwn(`${Resource.external_requests}`)
  .readOwn(`${Resource.favorites}`)
  .readOwn(`${Resource.healthcare_institutions}`)
  .readOwn(`${Resource.location}`)
  .readOwn(`${Resource.request_candidates}`)
  .readOwn(`${Resource.requests_temp}`)
  .createOwn(`${Resource.requests_temp}`)
  .updateOwn(`${Resource.requests_temp}`)
  .createOwn(`${Resource.requests_temp_direct}`)
  .readOwn(`${Resource.requests_temp_direct}`)
  .updateOwn(`${Resource.requests_temp_direct}`)
  .readOwn(`${Resource.users}`);

ac.grant('clinic_hrManager')
  .readOwn(`${Resource.analytics}`)
  .createOwn(`${Resource.candidates_blocked}`)
  .readOwn(`${Resource.candidates_blocked}`)
  .updateOwn(`${Resource.candidates_blocked}`)
  .deleteOwn(`${Resource.candidates_blocked}`)
  .readOwn(`${Resource.candidates_documents}`)
  .readOwn(`${Resource.candidates_presence}`)
  .readOwn(`${Resource.candidates_profiles}`)
  .readOwn(`${Resource.candidates_search}`)
  .readOwn(`${Resource.bookings}`)
  .createOwn(`${Resource.bookings}`)
  .createOwn(`${Resource.booking_requests}`)
  .readOwn(`${Resource.booking_requests}`)
  .updateOwn(`${Resource.booking_requests}`)
  .deleteOwn(`${Resource.booking_requests}`)
  .readOwn(`${Resource.booking_status}`)
  .readOwn(`${Resource.booking_documents}`)
  .readOwn(`${Resource.departments}`)
  .createOwn(`${Resource.favorites}`)
  .readOwn(`${Resource.favorites}`)
  .updateOwn(`${Resource.favorites}`)
  .deleteOwn(`${Resource.favorites}`)
  .readOwn(`${Resource.healthcare_institutions}`)
  .readAny(`${Resource.invite_new_users_from_candidate_profiles}`)
  .readOwn(`${Resource.location}`)
  .readAny(`${Resource.open_approvals}`)
  .readOwn(`${Resource.quotes_clinic}`)
  .readOwn(`${Resource.request_candidates}`)
  .createOwn(`${Resource.requests_perm}`)
  .readOwn(`${Resource.requests_perm}`)
  .createOwn(`${Resource.requests_temp}`)
  .readOwn(`${Resource.requests_temp}`)
  .updateOwn(`${Resource.requests_temp}`)
  .createOwn(`${Resource.requests_temp_direct}`)
  .readOwn(`${Resource.requests_temp_direct}`)
  .updateOwn(`${Resource.requests_temp_direct}`)
  .createOwn(`${Resource.message_feeds}`)
  .readOwn(`${Resource.message_feeds}`)
  .updateOwn(`${Resource.message_feeds}`);

ac.grant('doctari_mitarbeiter')
  .readAny(`${Resource.analytics}`)
  .readAny(`${Resource.bookings}`)
  .readAny(`${Resource.booking_requests}`)
  .readAny(`${Resource.booking_status}`)
  .readOwn(`${Resource.booking_documents}`)
  .readAny(`${Resource.candidate_availabilities}`)
  .readAny(`${Resource.candidates_blocked}`)
  .readAny(`${Resource.candidates_documents}`)
  .readAny(`${Resource.candidates_full}`)
  .readAny(`${Resource.candidates_presence}`)
  .readAny(`${Resource.candidates_profiles}`)
  .readAny(`${Resource.candidates_profiles_versions}`)
  .readAny(`${Resource.candidates_profiles_meta_data}`)
  .readAny(`${Resource.candidates_search}`)
  .readAny(`${Resource.candidates_long_term_with_doctari}`)
  .readAny(`${Resource.candidates_system_comments}`)
  .readAny(`${Resource.clinic_prefer_temporary_employment_contracts}`)
  .readAny(`${Resource.departments}`)
  .readAny(`${Resource.departments_shiftTimeConfig}`)
  .readAny(`${Resource.departments_shiftTimeConfig_priceCategory}`)
  .readAny(`${Resource.price_config}`)
  .readAny(`${Resource.external_requests}`)
  .readAny(`${Resource.favorites}`)
  .readAny(`${Resource.live_requests}`)
  .readAny(`${Resource.location}`)
  .readAny(`${Resource.message_feeds}`)
  .readAny(`${Resource.quotes_candidate}`)
  .readAny(`${Resource.quotes_clinic}`)
  .readAny(`${Resource.request_candidates}`)
  .readAny(`${Resource.request_candidates_swimlane}`)
  .readAny(`${Resource.requests_meta_data}`)
  .readAny(`${Resource.requests_perm}`)
  .readAny(`${Resource.requests_temp}`)
  .readAny(`${Resource.requests_temp_direct}`)
  .readAny(`${Resource.tokens}`)
  .readAny(`${Resource.users}`)
  .readAny(`${Resource.users_activity}`)
  .readAny(`${Resource.users_doctari_contact}`)
  .readAny(`${Resource.users_doctari_selectable_qualifications}`)
  .readAny(`${Resource.users_password}`)
  .readAny(`${Resource.users_sales_standin}`)
  .readAny(`${Resource.users_salesforceId}`)
  .readAny(`${Resource.change_clinic_user_email}`)
  .readAny(`${Resource.opportunities}`)
  .readAny(`${Resource.healthcare_institutions}`);

const DOCTARI_RESOURCES: Resource [] = [
  Resource.analytics,
  Resource.bookings,
  Resource.booking_requests,
  Resource.booking_status,
  Resource.booking_documents,
  Resource.candidate_availabilities,
  Resource.candidates_blocked,
  Resource.candidates_documents,
  Resource.candidates_presence,
  Resource.candidates_profiles,
  Resource.candidates_profiles_versions,
  Resource.candidates_profiles_meta_data,
  Resource.candidates_search,
  Resource.candidates_long_term_with_doctari,
  Resource.candidates_system_comments,
  Resource.clinic_prefer_temporary_employment_contracts,
  Resource.departments,
  Resource.departments_shiftTimeConfig,
  Resource.departments_shiftTimeConfig_priceCategory,
  Resource.doctari_dashboard_todos,
  Resource.price_config,
  Resource.emails,
  Resource.external_requests,
  Resource.favorites,
  Resource.healthcare_institutions,
  Resource.invite_new_users_from_candidate_profiles,
  Resource.live_requests,
  Resource.location,
  Resource.message_feeds,
  Resource.quotes_candidate,
  Resource.quotes_clinic,
  Resource.request_candidates,
  Resource.request_candidates_swimlane,
  Resource.requests_meta_data,
  Resource.requests_perm,
  Resource.requests_temp,
  Resource.requests_temp_direct,
  Resource.requests_temp_debug,
  Resource.tokens,
  Resource.users_activity,
  Resource.users_doctari_contact,
  Resource.users_doctari_selectable_qualifications,
  Resource.users_password,
  Resource.users_sales_standin,
  Resource.users_salesforceId,
  Resource.change_clinic_user_email,
  Resource.opportunities,
];

const grantFullAccessToOneResource = (access: Access, resourceName: Resource | string): void => {
  access
    .createAny(`${resourceName}`)
    .readAny(`${resourceName}`)
    .updateAny(`${resourceName}`)
    .deleteAny(`${resourceName}`);
};

const grantFullAccessToResources = (ac: AccessControl, role: Role, resourceNames: Resource[] | string []): void => {
  const access = ac.grant(role);
  resourceNames.forEach((resourceName) => grantFullAccessToOneResource(access, resourceName));
};

const doctariManager: Access = ac.grant('doctari_manager');
doctariManager.createAny(`${Resource.users}`).readAny(`${Resource.users}`).updateAny(`${Resource.users}`);
doctariManager.createAny(`${Resource.candidates_full}`).readAny(`${Resource.candidates_full}`).updateAny(`${Resource.candidates_full}`);
grantFullAccessToResources(ac, 'doctari_manager', DOCTARI_RESOURCES);

const doctariAdmin: Access = ac.grant('doctari_admin').extend('doctari_manager');
grantFullAccessToOneResource(doctariAdmin, Resource.reports);
grantFullAccessToOneResource(doctariAdmin, Resource.users);
grantFullAccessToOneResource(doctariAdmin, Resource.candidates_full);
doctariAdmin.readAny(`${Resource.audit_log}`);

grantFullAccessToResources(ac, 'doctari_developer', Object.keys(Resource));

ac.lock();

class InternalQuery {
  private readonly role: Role | undefined;

  constructor(roles: Role | undefined) {
    this.role = roles;
  }

  public grantAccess(): UserRole[] {
    const result: UserRole[] = [];
    const roleToRolesToSet = PERMISSIONS_TO_SET_ROLES.find(({ role }) => role === this.role);
    if (roleToRolesToSet) {
      result.push(...roleToRolesToSet.rolesToSet);
    }

    return result;
  }

  public createAny(resource: Resource): boolean {
    return this.role ? ac.can(this.role).createAny(resource.toString()).granted : false;
  }

  public createOwn(resource: Resource): boolean {
    return this.role ? ac.can(this.role).createOwn(resource.toString()).granted : false;
  }

  public readAny(resource: Resource): boolean {
    return this.role ? ac.can(this.role).readAny(resource.toString()).granted : false;
  }

  public readOwn(resource: Resource): boolean {
    return this.role ? ac.can(this.role).readOwn(resource.toString()).granted : false;
  }

  public updateAny(resource: Resource): boolean {
    return this.role ? ac.can(this.role).updateAny(resource.toString()).granted : false;
  }

  public updateOwn(resource: Resource): boolean {
    return this.role ? ac.can(this.role).updateOwn(resource.toString()).granted : false;
  }

  public deleteAny(resource: Resource): boolean {
    return this.role ? ac.can(this.role).deleteAny(resource.toString()).granted : false;
  }

  public deleteOwn(resource: Resource): boolean {
    return this.role ? ac.can(this.role).deleteOwn(resource.toString()).granted : false;
  }

  public allResourcesForRole(): ResourcesAccess {
    return Object.keys(Resource)
      .reduce((acc, curr) => {
        const res = curr as keyof typeof Resource;

        // Only use the string values from the enum as the number are repeated ones
        if (Number.isNaN(Number(res))) {
          acc.create.push({ [res]: this.createOwn(Resource[res]) });
          acc.read.push({ [res]: this.readOwn(Resource[res]) });
          acc.update.push({ [res]: this.updateOwn(Resource[res]) });
          acc.delete.push({ [res]: this.deleteOwn(Resource[res]) });
        }

        return acc;
      },
      { create: [], read: [], update: [], delete: [] } as ResourcesAccess);
  }

  public grantedResourcesForRole(): ResourcesAccess {
    const resources = this.allResourcesForRole();
    const approvedResources = Object.entries(resources).reduce((acc, [action, resource]) => {
      acc[action as keyof ResourcesAccess] = resource.filter((resourceAccess) => {
        return Object.values(resourceAccess)[0];
      });
      return acc;
    },
    { create: [], read: [], update: [], delete: [] } as ResourcesAccess);

    return approvedResources;
  }
}

const canRole = (personRole: Role): InternalQuery => new InternalQuery(personRole);

const can = (person: { role: Role } | undefined | null): InternalQuery => new InternalQuery(person?.role);

const is = (person: { role: Role } | undefined | null) => (role: Role | undefined): boolean => person?.role === role;

const isInGroup = (person: { role: Role } | undefined | null) => (group: Group | undefined): boolean => !!(
  group && person?.role && group.roles.includes(person.role)
);

export default {
  can,
  canRole,
  is,
  isInGroup,
};
