import React from 'react';
import { redirect } from 'react-router-dom';

import { AppRoot } from '@@frontend/AppRoot';
import RouterErrorBoundary from '@@frontend/components/AppRouter/components/RouterErrorBoundary';
import routes from '@@frontend/routes';

import type { RouteParams } from '@@common-legacy/routes/definitions';
import type { LoaderFunction } from '@remix-run/router/utils';
import type { RouteObject } from 'react-router';

// this is used by multiple routes groups, so pull it out to ensure we use the same webpackChunkName
const PublicRoutesLayout = (): Promise<{ Component: React.FunctionComponent }> => import('@@frontend/components/AppRouter/components/routes/PublicRoutesLayout'/* webpackChunkName: "PublicRoutesLayout" */);

const UserRoutes: RouteObject = {
  lazy: () => import('@@frontend/components/AppRouter/components/routes/UserRoutesLayout'/* webpackChunkName: "UserRoutesLayout" */),
  children: [{
    path: routes.accounts.index.pathTemplate,
    lazy: () => import('@@frontend/views/AccountsView/AccountsView'/* webpackChunkName: "AccountsView" */),
  }, {
    path: routes.accounts.create.pathTemplate,
    lazy: () => import('@@frontend/views/CreateAccount/CreateAccount'/* webpackChunkName: "CreateAccount" */),
  }, {
    path: routes.accounts.edit.pathTemplate,
    lazy: () => import('@@frontend/views/EditAccount/EditUserAccount'/* webpackChunkName: "EditUserAccountView" */),
  }, {
    path: routes.admin.availableCandidates.pathTemplate,
    lazy: () => import('@@frontend/views/AdminAvailableCandidates/AdminAvailableCandidates'/* webpackChunkName: "AdminAvailableCandidatesView" */),
  }, {
    path: routes.admin.auditLog.pathTemplate,
    lazy: () => import('@@frontend/views/AdminAuditLog/AdminAuditLog'/* webpackChunkName: "AdminAuditLog" */),
  }, {
    path: routes.admin.candidateMatchingDebugger.pathTemplate,
    lazy: () => import('@@frontend/views/AdminCandidateMatchingDebugger/CandidateMatchingDebugger'/* webpackChunkName: "CandidateMatchingDebugger" */),
  }, {
    path: routes.admin.candidateIndexStats.pathTemplate,
    lazy: () => import('@@frontend/views/AdminCandidateMatchingDebugger/CandidateIndexStats'/* webpackChunkName: "CandidateIndexStats" */),
  }, {
    path: routes.admin.emailInbox.pathTemplate,
    lazy: () => import('@@frontend/views/AdminEmailInbox/AdminEmailInbox'/* webpackChunkName: "AdminEmailInbox" */),
  }, {
    path: routes.admin.emailInboxWithRequestId.pathTemplate,
    lazy: () => import('@@frontend/views/AdminEmailInbox/AdminEmailInbox'/* webpackChunkName: "AdminEmailInbox" */),
  }, {
    path: routes.admin.requests.pathTemplate,
    lazy: () => import('@@frontend/views/AdminRequests/AdminRequestsView'/* webpackChunkName: "AdminRequests" */),
  }, {
    path: routes.admin.salesforceImport.pathTemplate,
    lazy: () => import('@@frontend/views/AdminSalesforceImport'/* webpackChunkName: "AdminSalesforceImport" */),
  }, {
    path: routes.admin.dmsImport.pathTemplate,
    lazy: () => import('@@frontend/views/AdminDmsImport/AdminDmsImport'/* webpackChunkName: "AdminDmsImport" */),
  }, {
    path: routes.admin.reports.pathTemplate,
    lazy: () => import('@@frontend/views/AdminReports'/* webpackChunkName: "AdminReports" */),
  }, {
    path: routes.healthcareInstitutions.index.pathTemplate,
    lazy: () => import('@@frontend/views/HealthcareInstitutionsView/HealthcareInstitutionsView'/* webpackChunkName: "HealthcareInstitutionsView" */),
  }, {
    path: routes.healthcareInstitutions.edit.pathTemplate,
    lazy: () => import('@@frontend/views/EditHealthcareInstitution/EditHealthcareInstitution'/* webpackChunkName: "EditHealthcareInstitution" */),
  }, {
    path: routes.healthcareInstitutions.departments.create.pathTemplate,
    lazy: () => import('@@frontend/views/CreateHealthcareInstitutionDepartment/CreateHealthcareInstitutionDepartment'/* webpackChunkName: "CreateHealthcareInstitutionDepartment" */),
  }, {
    path: routes.healthcareInstitutions.departments.edit.pathTemplate,
    lazy: () => import('@@frontend/views/EditHealthcareInstitutionDepartment/EditHealthcareInstitutionDepartment'/* webpackChunkName: "EditHealthcareInstitutionDepartment" */),
  }, {
    path: routes.healthcareInstitutions.tokens.create.pathTemplate,
    lazy: () => import('@@frontend/views/EditHealthcareInstitution/components/TokenConfigView/views/CreateExternalRequestToken'/* webpackChunkName: "CreateExternalRequestToken" */),
  }, {
    path: routes.healthcareInstitutions.tokens.show.pathTemplate,
    lazy: () => import('@@frontend/views/EditHealthcareInstitution/components/TokenConfigView/views/ShowExternalRequestToken'/* webpackChunkName: "ShowExternalRequestToken" */),
  }, {
    path: routes.home.pathTemplate,
    lazy: () => import('@@frontend/views/Home/Home'/* webpackChunkName: "Home" */),
  }, {
    path: routes.analytics.index.pathTemplate,
    lazy: () => import('@@frontend/views/AnalyticsDashboard/pages/AnalyticsDashboardView'/* webpackChunkName: "SalesDashboardView" */),
  }, {
    path: routes.messages.index.pathTemplate,
    lazy: () => import('@@frontend/views/Messages'/* webpackChunkName: "Messages" */),
  }, {
    path: routes.messages.feed.pathTemplate,
    lazy: () => import('@@frontend/views/MessageFeed/MessageFeed'/* webpackChunkName: "MessageFeed" */),
  }, {
    path: routes.myAccount.index.pathTemplate,
    lazy: () => import('@@frontend/views/EditAccount/EditMyAccount'/* webpackChunkName: "EditMyAccountView" */),
  }, {
    path: routes.myBookings.index.pathTemplate,
    lazy: () => import('@@frontend/views/MyBookings/MyBookings'/* webpackChunkName: "MyBookings" */),
  }, {
    path: routes.myBookings.details.pathTemplate,
    lazy: () => import('@@frontend/views/BookingDetails/BookingDetailsView'/* webpackChunkName: "BookingDetails" */),
  }, {
    path: routes.myPools.index.pathTemplate,
    lazy: () => import('@@frontend/views/MyPools'/* webpackChunkName: "MyPools" */),
  }, {
    path: routes.myPools.institution.pathTemplate,
    lazy: () => import('@@frontend/views/MyPool/MyPool'/* webpackChunkName: "MyPool" */),
  }, {
    path: routes.permanentRequests.index.pathTemplate,
    lazy: () => import('@@frontend/views/PermanentPlacementRequests/PermanentPlacementRequestsView'/* webpackChunkName: "PermanentPlacementRequests" */),
  }, {
    path: routes.profile.candidate.pathTemplate,
    lazy: () => import('@@frontend/views/CandidateProfile/CandidateProfileView'/* webpackChunkName: "CandidateProfileView" */),
  }, {
    path: routes.requests.create.pathTemplate,
    lazy: () => import('@@frontend/views/RequestFormView/CreateRequestFormView'/* webpackChunkName: "RequestFormView" */),
  }, {
    path: routes.requests.edit.pathTemplate,
    lazy: () => import('@@frontend/views/RequestFormView/EditRequestFormView'/* webpackChunkName: "RequestFormView" */),
  }, {
    path: routes.requests.extend.pathTemplate,
    lazy: () => import('@@frontend/views/EditLiveRequest/EditLiveRequestView'/* webpackChunkName: "EditLveRequestView" */),
  }, {
    path: routes.requests.index.pathTemplate,
    lazy: () => import('@@frontend/views/MyRequests/MyRequests'/* webpackChunkName: "MyRequests" */),
  }, {
    path: routes.requests.request.pathTemplate,
    lazy: () => import('@@frontend/views/RequestDetails/RequestDetailsView'/* webpackChunkName: "RequestDetails" */),
  }, {
    path: routes.calendar.index.pathTemplate,
    lazy: () => import('@@frontend/views/Calendar/CalendarView'/* webpackChunkName: "CalendarView" */),
  }, {
    path: routes.requests.opportunity.pathTemplate,
    lazy: () => import('@@frontend/views/SfOpportunity/SfOpportunityView'/* webpackChunkName: "SfOpportunityView" */),
  }, {
    path: routes.requests.requestCandidateAvailabilities.pathTemplate,
    lazy: () => import('@@frontend/views/CandidateRequestAvailabilities/CandidateRequestAvailabilities'/* webpackChunkName: "CandidateRequestAvailabilities" */),
  }, {
    path: routes.search.index.pathTemplate,
    lazy: () => import('@@frontend/views/CandidatesSearch/CandidatesSearch'/* webpackChunkName: "CandidatesSearch" */),
  }, {
    path: routes.userEnteredCandidatePage.pathTemplate,
    lazy: () => import('@@frontend/views/UserEnteredCandidatePage'/* webpackChunkName: "UserEnteredCandidatePage" */),
  }, {
    path: routes.watchlist.index.pathTemplate,
    lazy: () => import('@@frontend/views/WatchlistView'/* webpackChunkName: "WatchlistView" */),
  }, {
    path: routes.salesDashboard.pathTemplate,
    lazy: () => import('@@frontend/views/SalesDashboard/StandaloneSalesDashboardView'/* webpackChunkName: "SalesDashboardView" */),
  }, {
    path: routes.link.opportunity.pathTemplate,
    lazy: () => import('@@frontend/views/LinkOpportunity/LinkOpportunityView'/* webpackChunkName: "LinkOpportunityView" */),
  }],
};

const LoginRoutes: RouteObject = {
  lazy: () => import('@@frontend/components/AppRouter/components/routes/UserLoginRoutesLayout'/* webpackChunkName: "UserLoginRoutesLayout" */),
  children: [
    {
      path: routes.login.pathTemplate,
      lazy: () => import('@@frontend/views/Login/LoginView'/* webpackChunkName: "LoginView" */),
    }],
};

const PublicUserRoutes: RouteObject = {
  lazy: PublicRoutesLayout,
  children: [{
    path: routes.public.passwordReset.reset.pathTemplate,
    lazy: () => import('@@frontend/views/Login/ResetPasswordView'/* webpackChunkName: "PasswordReset" */),
  }, {
    path: routes.public.passwordReset.request.pathTemplate,
    lazy: () => import('@@frontend/views/Login/PasswordResetRequest/PasswordResetRequest'/* webpackChunkName: "PasswordResetRequest" */),
  }, {
    path: routes.public.changeEmail.confirm.pathTemplate,
    lazy: () => import('@@frontend/views/EditAccount/UserForm/UserEmailForm/ConfirmEmailChange/ConfirmEmailChange'/* webpackChunkName: "ConfirmEmailChange" */),
  }, {
    path: routes.public.registerInvitedUser.pathTemplate,
    lazy: () => import('@@frontend/views/RegisterInvitedUser/RegisterInvitedUserView'/* webpackChunkName: "RegisterInvitedUser" */),
  }, {
    path: routes.public.logout.pathTemplate,
    lazy: () => import('@@frontend/views/Logout/LogoutView'/* webpackChunkName: "LogoutView" */),
  }],
};

const CandidateRoutes: RouteObject = {
  lazy: () => import('@@frontend/components/AppRouter/components/routes/CandidateRoutesLayout'/* webpackChunkName: "CandidateRoutesLayout" */),
  children: [{
    path: routes.public.candidate.bookingRequestDecision.pathTemplate,
    lazy: () => import('@@frontend/views/Availabilities/BookingRequestDecisionView'/* webpackChunkName: "BookingRequestDecisionView" */),
  }, {
    path: '/booking-request-response/:request_id/:swimlane/:version?',
    loader: (async ({ params }) => {
      const { request_id, version } = params as RouteParams<typeof routes.public.candidate.bookingRequestDecision>;

      if (!request_id) throw new Error('request_id is required');

      return redirect(routes.public.candidate.bookingRequestDecision.getUrl({ params: { request_id, version } }));
    }) as LoaderFunction,
  }, {
    path: routes.public.candidate.chat.pathTemplate,
    lazy: () => import('@@frontend/views/Chat/ChatView'/* webpackChunkName: "ChatView" */),
  }, {
    path: routes.public.candidate.sendAvailabilities.pathTemplate,
    lazy: () => import('@@frontend/views/Availabilities/SendAvailabilitiesView'/* webpackChunkName: "SendAvailabilitiesView" */),
  }, {
    path: routes.public.candidate.unavailable.pathTemplate,
    lazy: () => import('@@frontend/views/Availabilities/UnavailableView'/* webpackChunkName: "UnavailableView" */),
  }],
};

const PublicCandidateRoutes: RouteObject = {
  lazy: PublicRoutesLayout,
  children: [
    {
      path: routes.public.candidate.candidateLogout.pathTemplate,
      lazy: () => import('@@frontend/views/CandidateLoggedOut'/* webpackChunkName: "CandidateLoggedOutView" */),
    },
    {
      path: routes.public.candidate.tokenExpired.pathTemplate,
      lazy: () => import('@@frontend/views/TokenExpired'/* webpackChunkName: "TokenExpiredView" */),
    }],
};

const PublicStaticRoutes: RouteObject = {
  lazy: PublicRoutesLayout,
  children: [
    {
      path: routes.public.dataProtection.pathTemplate,
      lazy: () => import('@@frontend/views/DataProtection/DataProtection' /* webpackChunkName: "DataProtection" */),
    },
    {
      path: routes.internal.healthCheck.pathTemplate,
      lazy: () => import('@@frontend/views/HealthCheck/HealthCheck'/* webpackChunkName: "HealthCheck" */),
    },
  ],
};

const PrintPDFRoutes: RouteObject = {
  lazy: () => import('@@frontend/components/AppRouter/components/routes/PrintPDFRoutesLayout'/* webpackChunkName: "PrintPDFRoutesLayout" */),
  children: [
    {
      path: routes.profile.candidatePdf.pathTemplate,
      lazy: () => import('@@frontend/views/CandidateProfile/PrintView/CandidateProfilePrintView'/* webpackChunkName: "PrintView" */),
    }],
};

const CatchAllNotFoundRoute: RouteObject = {
  lazy: PublicRoutesLayout,
  children: [
    {
      path: '*', // catch all
      lazy: () => import('@@frontend/views/NotFound/NotFoundView'/* webpackChunkName: "NotFoundView" */),
    }],
};

export const AppRouterConfig: RouteObject[] = [
  {
    errorElement: <RouterErrorBoundary/>,

    element: <AppRoot/>,

    children: [
      UserRoutes,
      LoginRoutes,
      PublicUserRoutes,

      CandidateRoutes,
      PublicCandidateRoutes,

      PublicStaticRoutes,

      PrintPDFRoutes,

      CatchAllNotFoundRoute,
    ],
  },
];
